// ------------------------
// HERO SECTION TYPEWRITER EFFECT
// ------------------------

import Typewriter from '/node_modules/typewriter-effect/dist/core';

new Typewriter('#typewriter', {
  strings: ['Stwórz', 'Odśwież', 'Odkryj', 'Odmień'],
  autoStart: true,
  loop: true,
  delay: 50,
});